module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Viel Leicht","short_name":"viel-leicht","start_url":"/","background_color":"#ffffff","theme_color":"#CBD300","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://matomo.suedbayerische-fleischwaren.de","siteUrl":"https://suedbayerische-fleischwaren.de"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
